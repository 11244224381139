import { ajax } from "./ajax";
import { Principal } from "./util/principal";
import * as appConfig from "../../app.json";

export async function login(username, password, ValidateCodeId, ValidateCode, service) {
    const result = await ajax({
        method: "CCLOGIN",
        action: "login",
        data: {
            loginUser: username,
            password,
            ValidateCodeId,
            ValidateCode,
            service,
            //登录类型
            ClientType:1  //0-未知 1-web  2-移动客户端
        }
    });

    Principal.store(result && result.data);
    return result;
}

export async function loginCode(service, version) {
    const result = await ajax({
        method: "GetValidateCode",
        action: "loginAction",
        data: {
            service,
            version
        }
    });
    Principal.store(result && result.data);
    return result;
}


//登录前的验证
export async function loginTest(service, version, username) {
    const result = await ajax({
        method: "GetUserStatus",
        action: "loginAction",
        data: {
            username,
            service,
            version
        }
    });
    return result;
}

//登录前的验证
export async function getRSA() {
    const result = await ajax({
        method: "GetRSA",
        action: "loginAction",
        data: {
            "service":"AuthCenter",
        }
    });
    return result.data;
}


$("#version").text(appConfig.version.substring(0, 5));
