const { development } = APP_CONFIG;

const _debug = console.info || console.log;

export const debug = development
    ? (...args) => {
        const fn = args[0];
        if (typeof fn === "function") {
            fn();
            return;
        }
    }
    : () => { };
