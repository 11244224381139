function parseJson(json) {
    if (!json) {
        return null;
    }

    try {
        return JSON.parse(json);
    } catch (e) {
        return null;
    }
}

// export const storage = window.sessionStorage
//     ? {
//         load(key) {
//             const json = window.sessionStorage.getItem(key);
//             return parseJson(json);
//         },
//         store(key, obj) {
//             const json = JSON.stringify(obj);
//             window.sessionStorage.setItem(key, json);
//         }
//     }
//     : {
//         load(key) { },
//         store(key, obj) { }
//     };


//用户信息和token应该存在localStroage里 避免因为进程问题导致加载错误
export const storage = window.localStorage
    ? {
        load(key) {
            const json = window.localStorage.getItem(key);
            return parseJson(json);
        },
        store(key, obj) {
            const json = JSON.stringify(obj);
            window.localStorage.setItem(key, json);
        }
    }
    : {
        load(key) { },
        store(key, obj) { }
    };
