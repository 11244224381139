import { defaultErrorHandler } from "./error";
import { Principal } from "./util/principal";
import { debug } from "../dev";

var appSetConfig

async function loadConfig() {
    if (localStorage.getItem("appSetConfig")) {
        appSetConfig = JSON.parse(localStorage.getItem("appSetConfig"))
    } else {
        appSetConfig = await $.ajax("config.json");
        if (appSetConfig.config == undefined) {
            appSetConfig = JSON.parse(await $.ajax("config.json"));
        }
        localStorage.setItem("appSetConfig", JSON.stringify(appSetConfig))
    }
}

/**
 * 使用 jQuery 发出 MIME 为 `plain/text` 的请求
 * @param {*} settings
 *      - `action` - 对应服务端要求的 action，若无 `Action` 后缀，会自动补充
 *      - `method` - 对应服务端要求的 method，若未指定则与 action 相同（不补充后缀）
 *      - `data` - 要提交给后台的其它数据（模型）
 *      - `injector` - 指定一个函数，它接收 data 和 principal 作为参数，用于向 data 注入数据
 * @return {Promise}
 */
export async function prepare(settings) {
    const URL = appSetConfig.config.HttpUrl;
    const {
        action: _action,
        url = URL,
        method = _action,
        data = {},
        injector = m => m
    } = settings;

    const action = !/action$/i.test(_action) ? `${_action}Action` : _action;
    let injectdata = injector(data, Principal.get());
    switch (action) {
        case "loginAction":
            injectdata.service = "AuthCenter";
            break;
        case "DepartAction":
            injectdata.service = "AuthCenter";
            break;
        case "DutyAction":
            injectdata.service = "AuthCenter";
            break;
        case "StaffAndLoginAction":
            injectdata.service = "AuthCenter";
            break;
        case "FeedTypeAction":
            injectdata.service = "AuthCenter";
            break;
        case "CompanyAction":
            injectdata.service = "AuthCenter";
            break;
        case "RoleAction":
            injectdata.service = "AuthCenter";
            break;
        case "LimsDataAction":
            injectdata.service = "ReportService";
            break;
        case "ReportAction":
            injectdata.service = "ReportService";
            break;
        case "BusinessTypeAction":
            injectdata.service = "apply";
            break;

    }

    return Object.assign({ method, action }, injectdata);
}

/**
 * 使用 jQuery 发出 MIME 为 `plain/text` 的请求
 * @param {*} settings
 *      - `action` - 对应服务端要求的 action，若无 `Action` 后缀，会自动补充
 *      - `method` - 对应服务端要求的 method，若未指定则与 action 相同（不补充后缀）
 *      - `data` - 要提交给后台的其它数据（模型）
 *      - `injector` - 指定一个函数，它接收 data 和 principal 作为参数，用于向 data 注入数据
 * @return {Promise}
 */
export async function ajax(settings) {
    await loadConfig()
    const URL = appSetConfig.config.HttpUrl;
    const data = await prepare(settings);
    const result = await (async () => {
        try {
            return await jQuery
                .ajax(URL, {
                    type: "post",
                    dataType: "json",
                    data: JSON.stringify(data),
                    contentType: "application/json",
                    // data:{request: JSON.stringify(data)}
                });

        } catch (e) {
            debug("[Ajax Error]", e);
            return {
                code: -1,
                message: "网络繁忙，请稍后再试"
            };
        }
    })();

    const errorHandler = settings.error === false
        ? () => { }
        : settings.error || defaultErrorHandler;

    errorHandler(result);
    return result;
}
