import Principal from "./util/api/util/principal";

// 时间格式规范  
var regex_dateTime = /\d{4}\-\d{2}\-\d{2} \d{2}\:\d{2}:\d{2}/;
var dateTime = /\d{4}\-\d{2}\-\d{2}/;


$.extend($.fn.validatebox.defaults.rules, {

    // 字符最大长度（param传参）  
    maxLength: {

        validator: function (value, param) {

            return value.length <= param[0];

        },

        message: "字段长度最多为{0}个字"

    },

    // 验证姓名，可以是中文或英文  

    name: {

        validator: function (value) {

            return /^[\u0391-\uFFE5]{1,20}$/i.test(value) | /^\w+[\w\s]+\w+$/i.test(value);

        },

        message: "姓名字数过多或应为中文或者英文"

    },

    //验证密码是否一致
    equalTo: {
        validator: function (value, param) {
            let val = ""
            let user = Principal.get();
            let PasswordComplexity = user.PasswordComplexity;
            //密码强度
            switch (PasswordComplexity) {
                case "3":
                case 3:
                    val = document.getElementById('Maxpassword').value
                    break;
                case "2":
                case 2:
                    val = document.getElementById('Middlepassword').value
                    break;
                case "1":
                case 1:
                    val = document.getElementById('Minpassword').value
                    break;
            }
            return val === value;
        },
        message: "两次密码不一致"
    },

    // 验证密码最大难度，密码中必须包含大小写字母、数字、特殊字符，至少8个字符，最多20个字符；

    Maxpassword: {

        validator: function (value) {
            const regs = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}');
            return value.length >= 8 && value.length <= 20 && regs.test(value);

        },

        message: "密码中必须包含大小写字母、数字、特殊字符，至少8个字符，最多20个字符"

    },

    // 验证密码中级难度，密码中必须包含字母（不区分大小写）、数字、特殊字符，至少8个字符，最多20个字符；

    Middlepassword: {

        validator: function (value) {
            const regs = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,20}');
            return value.length >= 8 && value.length <= 20 && regs.test(value);
        },

        message: "密码中必须包含字母（不区分大小写）、数字、特殊字符，至少8个字符，最多20个字符"

    },
    // 验证密码低级难度，密码中必须包含字母（不区分大小写）、数字，至少8个字符，最多20个字符；

    Minpassword: {

        validator: function (value) {
            const regs = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,20}');
            return value.length >= 8 && value.length <= 20 && regs.test(value);
        },
        message: "密码中必须包含字母（不区分大小写）、数字，至少8个字符，最多20个字符"

    },


    // 验证身份证  

    idcard: {

        validator: function (value) {

            return /^\d{15}(\d{2}[Xx0-9])?$/i.test(value);

        },

        message: "身份证应为15位或者18位"

    },

    // 验证IP地址  
    ip: {
        validator: function (value) {
            return /\d+\.\d+\.\d+\.\d+/.test(value);
        },
        message: "IP地址格式不正确"
    },

    //年龄验证  
    age: {

        validator: function (value) {

            return /^[0-9]{1,2}$/i.test(value); //0-99  

        },

        message: "您输入的年龄不合法"

    },

    // 验证电话号码  
    phone: {

        validator: function (value) {

            return /^1\d{10}$/i.test(value) || /^0\d{2,3}-?\d{7,8}$/i.test(value);

        },

        message: "电话号码正确格式:15288888888或020(0310)-88888888"

    },

    // 验证数字,整数或小数  
    number: {

        validator: function (value) {

            return /^\d{1,10}(\.\d{0,4})?$/i.test(value);

        },

        message: "请输入正确的重量"

    },

    // 验证数字,只能为整数  
    integer: {

        validator: function (value) {

            return /^\d{1,12}$/i.test(value);

        },

        message: "请输入一个整数"

    },
    // 验证数字,只能为正数
    positiveNumber: {

        validator: function (value) {

            return /^\d+(?=\.{0,1}\d+$|$)/i.test(value);

        },

        message: "请输入一个非负数"

    },

    // 验证是否全为数字
    isNumber: {

        validator: function (value) {

            return /^[0-9]+$/i.test(value);

        },

        message: "请输入数字"

    },

    //验证数字 只能为正整数
    PositiveInteger: {

        validator: function (value) {
            return /^\+?[1-9][0-9]*$/i.test(value);

        },

        message: "请输入一个正整数"

    },


    // 时间验证  
    //@author ren  
    /* start */
    endToStart: {

        validator: function (value, param) {

            return value > $("#" + param[0] + " input[name='" + param[1] + "']").val(); //结束时间>开始时间  

        },

        message: "结束时间应晚于起始时间"

    },

    startToEnd: {

        validator: function (value, param) {

            return value > $("#" + param[0]).datetimebox("getValue"); //结束时间>开始时间  

        },

        message: "结束时间应晚于起始时间"

    },

    datetimeValidate: {

        validator: function (value, param) {

            return dateTime.test(value); //验证时间格式是否规范  

        },

        message: "时间格式应为 2015-01-01"

    }
    /* end */
});