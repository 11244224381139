import { storage as storageUtil } from "./storage";

const fields = ["userName", "token"];

function parseJson(json) {
    if (!json) {
        return null;
    }

    try {
        return JSON.parse(json);
    } catch (e) {
        return null;
    }
}

const KEY = "TOKEN";

const storage = {
    load: () => storageUtil.load(KEY),
    store: user => storageUtil.store(KEY, user)
};

const caches = [storage];

function localLoadToken() {
    let user = null;
    caches.some(cache => {
        user = cache.load();
        return user;
    });
    return user;
}

function localStoreToken(user) {
    caches.forEach(cache => cache.store(user));
}

export class Principal {
    static get() {
        const user = storage.load();
        return user;
    }

    static store(user) {
        localStoreToken(user);
    }

    constructor(user) {
        this._isValid = user && fields.every(field => user[field]);

        if (!this._isValid) {
            this._user = {};
            return;
        }

        this._user = user;
        fields.forEach(field => this[`_${field}`] = user[field] || void 0);
    }

    get(name) {
        return this._user[name];
    }

    get isValid() {
        return this._isValid;
    }

    get username() {
        return this._UserName;
    }

    get realName() {
        return this._user.realName || this._userName;
    }

    get companyId() {
        return this._user.currCompanyId;
    }

    get token() {
        return this._Token;
    }
}

export default Principal;