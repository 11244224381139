import { prepare, ajax } from "./ajax";
import { login, loginCode, loginTest, getRSA } from "./login";
import { inject } from "./injection";

var appSetConfig = JSON.parse(localStorage.getItem("appSetConfig"));

function prepareData(action, method, data) {
    return prepare({
        action,
        method,
        data,
        injector: inject
    });
}

async function call(action, method, data, settings = {}) {
    if ($.messager) {
        $.messager.defaults.ok = "确定";
        $.messager.defaults.cancel = "取消";
    }
    return ajax({
        action,
        method,
        data,
        injector: inject,
        ...settings
    })
        .then(ajaxResult => {
            if (ajaxResult.code === 10) {
                $.messager.confirm({
                    title: "提示",
                    msg: "登录超时 系统将回到登录页面",
                    fn: ok => {
                        if (ok) {
                            top.location.href = "/login.html";
                        }
                    }
                });
            }
            if (ajaxResult.code === 14) {
                $.messager.confirm({
                    title: "提示",
                    msg: ajaxResult.message,
                    fn: ok => {
                        if (ok) {
                            top.location.href = "/login.html";
                        }
                    }
                });
            }
            return ajaxResult;
        });
}

async function callDirectly(action, method, data, settings = {}) {
    return ajax({
        action,
        method,
        data,
        ...settings
    });
}

async function getURL() {
    const url = appSetConfig.config.HttpUrl;
    return url;
}


async function getApkURL() {
    if (!appSetConfig) {
        appSetConfig = JSON.parse(localStorage.getItem("appSetConfig"));
    }
    const url = appSetConfig.config.ApkUrl;
    return url;
}

async function getMenuTitle() {
    const menuTitle = appSetConfig.config.menuTitle;
    return menuTitle;
}


export const api = window.api = {
    ajax,
    prepare: prepareData,
    call,
    callDirectly,
    login,
    loginCode,
    loginTest,
    getRSA,
    url: async () => {
        let url = await getURL()
        return url;
    },
    apiUrl: async () => {
        var r = await getApkURL();
        return r;
    },
    menuTitle: async () => {
        let menuTitle = await getMenuTitle()
        return menuTitle;
    },
};

export default api;
