import { debug } from "../dev";

export function defaultErrorHandler(model) {
    debug("[Ajax Error]", model);
    if (model.code) {
        if (model.code === 13 || model.code === 14) {
            return;
        } else {
            if ($.messager) {
                $.messager.alert({
                    title: "信息",
                    icon: "warning",
                    content: model.message || "远程操作发生错误，请重试或与管理员联系"
                });
            }
        }
    }
}