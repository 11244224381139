
export function injectCompany(data, CompanyId) {
    if (!data) {
        return;
    }

    if (data.CompanyId === void 0) {
        return;
    }

    if (!isNaN(parseInt(data.CompanyId))) {
        return;
    }

    const name = data.CompanyId || "company_id";
    if (name !== "CompanyId") {
        delete data.CompanyId;
    }
    data[name] = CompanyId;
}

export function inject(data, principal) {
    let service = "AuthCenter";
    let version = 1.0;
    // 注入 CompanyId
    const loginUser = principal.UserName;
    const loginToken = principal.Token;
    let feed_type_id;
    const feed = JSON.parse(localStorage.getItem("feed"));
    if (feed) {
        feed_type_id = feed.feedid;
    }
    const company_id = principal.CurrCompanyId;
    const injection = { loginUser, loginToken, feed_type_id, company_id, service, version };
    const injected = Object.assign(injection, data);
    injectCompany(injected, company_id);
    return injected;
}
